import React, { useState, useEffect } from 'react';
import useSound from 'use-sound';
import sound from '../sounds/home.mp3';

const Bgm = (music) => {

  const [play, { stop, pause }] = useSound(sound);
  //play({ volume: 0.4 });

  return (
    <div class="audio_wrap">
      <p>🎵BGM🎵</p>
      {/* <input id="volumeControl" defaultValue="0.5" type="range" min="0" max="1" step="0.1" value="0.5"></input> */}
      {/* <button onClick={() => play()}>Play</button> */}
      <button onClick={() => pause()}>On</button>
      <button onClick={() => pause()}>Off</button>
      <p>※調整中</p>
    </div>
  )
}

export default Bgm;