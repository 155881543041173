import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import './style/Navbar.css';

const Navbar = (isAuth) => {

    isAuth = JSON.parse(window.localStorage.getItem("isAuth"));

    const click = () => {
        console.log(isAuth);
    }

    const navigate = useNavigate();


  return (
    <nav>
        <ul>
            {/*             
            {isAuth ? (
                <li><Link to="/" onClick={click} activeClassName="active">Home</Link></li>
            ) : (
                <></>
            )}
            {isAuth ? (
                <li><Link to="/map" onClick={click} activeClassName="active">Map</Link></li>
            ) : (
                <></>
            )} */}
        </ul> 
    </nav>
  )
}

export default Navbar;