import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import GoogleMap from "./GoogleMap";

const Map = (isAuth) => {
    isAuth = JSON.parse(window.localStorage.getItem("isAuth"));
    //console.log(isAuth);
    if(isAuth){
      return (
        <motion.main
          className='container'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.4 }}
          >  
            <div className='map'>
              <div className='introduction'>
                <h2>Happy Birthday 2024 on March 29th</h2>
                <p>
                誕生日おめでとう!<br />
                本年も貴殿の生誕日という<br className='sp'/>善き地点に立ち会えること、<br className='sp'/>ざわ一同 誠に光栄です。<br />
                輝かしい一年の幕開けと<br className='sp'/>春の訪れを楽しむべく、<br className='sp'/>ささやかな祝宴を用意しましたので<br className='sp'/>主賓として御列席願います。<br />
                <br />
                日時: 3月31日(日)11時11分<br />
                場所: <a href="https://maps.app.goo.gl/NQR2rVuRtdhJ8Knh6" target='_blank' className='link'>Bistro yen</a><br />
                ドレスコード:<br className='sp'/>顔色のよく見える服、<br className='sp'/>歩きやすいパンプス・ローファー等<br className='sp'/>(ヒール非推奨)
                </p>
                
              </div>
              <div id="googlemap" className='googlemap'>
                <GoogleMap 
                  defaultPosition={{
                    lat: 35.681756452300725,
                    lng: 139.7788850659801, 
                  }}
                />
              </div>
              <div className='caption-list'>
                <ol>
                  <li>Zawa and the Crimson Stone / zawaと真紅の石 @KABUTO ONE</li>
                  <li>Zawa and the Pledged Luncheon / zawaと約束の午餐 @Bistro yen </li>
                  <li>Zawa and the 始まりの Bank / zawaと... @銀行発祥の地</li>
                  <li>Zawa and the Three Statues / Zawaと三つの.. @3人の乙女</li>
                  <li>Zawa and the 〇〇 Shrine / zawaと... @日本橋日枝神社分社</li>
                  <li>Zawa and the 〇〇の井戸 / zawaと.... @名水白木の井戸</li>
                  <li>Zawa and the Seven-Fortunes’ Garden /  zawaと七福の庭園 @名水白木の井戸</li>
                </ol>
              </div>
            </div>
        </motion.main>
      )
    }else{
      return(
        <>
          <p>このページを見るにはログインが必要です。</p>
          <Link to="/">LOGIN</Link>
        </>

      )
    }

}

export default Map;