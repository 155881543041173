import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import DocumentMeta from 'react-document-meta';
import { motion, AnimatePresence } from "framer-motion";

import Home from "./components/Home";
// import Login from "./components/Login";
import Logout from "./components/Logout";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import Map from "./components/Map";

import { useState, useEffect } from "react";

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoaded] = useState(false);

  //const inputRef = useRef(null);

  // useEffect(() => {
  //     console.log(isAuth);
  // }, [isAuth]);

  const meta = {
    title: 'てる Happy Birthday | 2024.03.29',
    description: '',
    canonical: '',
    meta: {
      charset: 'utf-8',
    }
  };

  return (
      <Router>
        <link rel="icon" href="/favicon.ico" sizes="32x32" />
        <link rel="icon" href="/icon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.webmanifest"></link>
        <DocumentMeta {...meta} />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home isAuth={isAuth} setIsAuth={setIsAuth} />}></Route>
          <Route path="/logout" element={<Logout isAuth={isAuth} setIsAuth={setIsAuth} />}></Route>
          <Route path="/map" element={<Map isAuth={isAuth} setIsAuth={setIsAuth} />}></Route>
        </Routes>
        <Footer />
        {/* <ScrollRestoration /> */}
      </Router>
  );
}

export default App;
