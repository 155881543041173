import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import Loader from "./Loader";

const Home = ({ isAuth, setIsAuth }) => {
    isAuth = window.localStorage.getItem("isAuth");

    const [passwordErrorText, setPasswordErrorText] = useState(false);
    const [formData, setFormData] = useState({
        password: "",
    });

    // loading animation
    const [isLoading, setIsLoaded] = useState(false);
    
    //setIsLoaded(false);
    //setTimeout(function(){
    //    setIsLoaded(true);
    //}, 2000);


    const handleChange = (event) => {
        //event.preventDefault();
        const { name, value } = event.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const navigate = useNavigate();

    const loginWithPassword = (event) => {
        //passcode = 20100923 // the date since zawa made
        event.preventDefault();
        console.log(formData.password);

        if(formData.password === "20100923"){ //passcode = 20100923 // the date since zawa made
            localStorage.setItem("isAuth", JSON.stringify(true));
            //setIsAuth = true;
            //isAuth = true;
            console.log(window.localStorage.getItem("isAuth"));
            navigate("/map");
        }else{
            setPasswordErrorText("パスワードが間違っています");
            //passwordErrorText = "パスワードが間違っています";
        }

        //const [loginPassword, SetLoginPassword] = useState("");
    };

    function goToZawaBlog () {
        window.open("https://zawa.nmtm.work/", '_blank');
    }


    if(isAuth){
        return (
            <motion.main
                className='container'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6, delay: 0.4 }}
            >
                <div className='introduction'>
                    <h2>🍵</h2>
                    <p>LINKS</p>
                    <p onclick={goToZawaBlog} className='link'><a href="https://zawa.nmtm.work/">zawa blog</a></p>
                </div>
                
            </motion.main>
        )
    }else{
        //if(isLoading){
        //    <Loader />
        //}else{
            return (
                <motion.main
                    className='container'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                >
                <div className='introduction login'>
                    <h2>8桁の数字を入力してください</h2>
                    <p>秘密の質問:ざわのメモリアルナンバー</p>
                    <form onSubmit={loginWithPassword}>
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                        ></input>
                        {passwordErrorText && <span className='error'>{passwordErrorText}</span> }
                        <input type="submit" value="送信" className='btn'/>
                    </form>
                </div>
                </motion.main> 
            )
        //}
    }
}

export default Home;