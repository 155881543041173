import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Logout = ({ isAuth, setIsAuth }) => {
    const navigate = useNavigate();

    const logout = () => {
        localStorage.setItem("isAuth", false);
        localStorage.clear();
        //setIsAuth = false;
        isAuth = false;
        navigate("/login");
    };

    return (
        <main className='container'>
            <p>ログアウトしました<br />
            <Link to="/" activeClassName="active">Home</Link>へ戻る</p>
        </main>
    )
}

export default Logout;