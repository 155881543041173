import React from 'react';
import { motion } from "framer-motion";

const Loader = (isLoading, setIsLoaded) => {
  return (
    <div className='loader-container'>
        <div className='spinner'>
            <img src="images/map.jpg" />
        </div>
    </div>
  )
}

export default Loader;